<template>
  <div class="d-flex justify-content-center">
    <div class="col-12 col-lg-6 col-md-7 col-xl-5">
      <a-card v-if="!isSuccessWithdraw">
        <div class="text-center">
          <strong> {{ $t('account.inputData') }} </strong>
        </div>
        <div class="mt-3">
          <a-form :form="form" @submit.prevent="() => modalConfirmation = true">
            <a-form-item
              :label="$t('account.bankAccount')"
            >
              <div class="card" v-if="selectedBank">
                <div class="d-flex justify-content-between">
                  <div class="pl-2">
                    {{ selectedBank.bank_name }} / {{ selectedBank.bank_accountnr }} / {{ selectedBank.bank_accountname }}
                  </div>
                  <div class="pr-2" v-if="selectedBank.is_verified">
                    <Tick />
                  </div>
                </div>
              </div>
              <div class="mb-3" v-if="selectedBank">
                <a-button size="small" @click.prevent="() => (modalSelectBank = true)"> {{ $t('account.changeBank') }} </a-button>
              </div>
              <div class="mb-3" v-if="!selectedBank">
                <a-button size="small" @click.prevent="openModal"> {{ $t('account.addBank') }} </a-button>
              </div>
            </a-form-item>
            <div class="d-flex justify-content-between">
              <div> {{ $t('account.ewalletSaldo') }} </div>
              <div> {{ !commission.ewallet ? 0 : commission.ewallet | currency }} </div>
            </div>
            <a-form-item
              :label="$t('account.withdrawAmount')"
              class="mt-3"
              :extra="$t('account.helpWithdraw', { value: withdraw_min_amount })"
            >
              <a-input-number
                v-decorator="['amount', {
                  initialValue: commission.ewallet && commission.ewallet,
                  rules: rules.value
                }]"
                :formatter="value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\Rp\s?|(,*)/g, '')"
                :min="withdraw_min_amount"
                :max="commission.ewallet"
                style="width: 75%;"
                @change="inputNumberChange"
              />
            </a-form-item>
            <div class="text-muted">Transfer fee: {{ 5000 | currency }} </div>
            <a-form-item
              class="mt-3"
            >
              <a-checkbox
                v-decorator="['checkbox', { rules: [{ required: true }]}]"
              >
                {{ $t('account.dataCorrect') }}
              </a-checkbox>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                size="large"
                class="text-center"
                htmlType="submit"
                :loading="loading"
                :disabled="commission.ewallet < withdraw_min_amount || form.getFieldValue('amount') < withdraw_min_amount"
              >
                <strong>Submit</strong>
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-card>
      <a-card v-if="isSuccessWithdraw">
        <div class="row">
          <div class="col-12 d-flex justify-content-center text-center">
            <a-icon type="check-circle" style="color: green; font-size: 100px" />
          </div>
          <div class="col-12 d-flex justify-content-center text-center mt-4">
            <h6> {{ $t('account.notifSuccessWithdraw') }} </h6>
          </div>
          <div class="col-12 text-center">
            <div> {{ $t('account.totalEwalletAmmount') }} </div>
            <!-- <div> <strong> {{ form.getFieldValue('amount') | currency }} </strong> </div> -->
            <div> <strong> {{ amount | currency }} </strong> </div>
            <div> {{ $t('account.willTransfer') }} </div>
            <div> <strong> {{ selectedBank.bank_name }} / {{ selectedBank.bank_accountnr }} / {{ selectedBank.bank_accountname }} </strong> </div>
          </div>
          <div class="col-12 d-flex justify-content-center text-center mt-4">
            <p class="text-bold"> {{ $t('account.notifSuccessWithdraw2') }} </p>
          </div>
        </div>
      </a-card>
    </div>
    <a-modal v-model="modalVisible" title="Add Bank Account" :footer="null" @cancel="handleCancel">
      <FormAddBank
        @handle-modal="handleModalFormBank"
        :bank="bank"
        :bankList="bankList"
        :isFromWithdraw="true"
      />
    </a-modal>
    <a-modal v-model="modalSelectBank" title="Select Bank" :footer="null" @cancel="handleCancel" v-if="!!bank.length">
      <a-form-item>
        <a-select
          v-decorator="[
            'bank_id',
            {
              rules: [{
                required: true,
                message: 'Please select bank account'
              }],
            }
          ]"
          :default-value="`${bank[0].bank_name} - ${bank[0].bank_accountnr}`"
          style="width: 100%"
          @change="(value) => setSelectedBank(value)"
        >
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes :vnodes="menu" />
            <a-divider style="margin: 4px 0;" />
            <div
              style="padding: 4px 8px; cursor: pointer;"
              @mousedown="e => e.preventDefault()"
              @click="openModal"
            >
              <a-icon type="plus" /> Add New Bank Acccount
            </div>
          </div>
          <a-select-option
            v-for="(item, index) in bank"
            :key="index"
            :value="item.id_bank_account"
          >
            <!-- {{ item.bank_name }} - {{ item.bank_accountnr }} -->
            <div class="d-flex justify-content-between">
              <div class="pl-2">
                {{ item.bank_name }} - {{ item.bank_accountnr }}
              </div>
              <div class="pr-2" v-if="item.is_verified">
                <Tick />
              </div>
            </div>
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-modal>
    <a-modal v-model="modalOtp" :footer="null" @cancel="handleCancelModalOtp">
      <FormOtpBank
        @handleVerifyBank="handleVerifyBank"
        @handleResendOtp="handleResendOtp"
        @setOtpBank="setOtpBank"
        :phoneNumber="phoneNumber"
        :modalState="modalOtp"
        :timestamp="timestamp"
        :isStartCountdown="startCountdown"
        :otp="otp"
        ref="otpBank"
      />
    </a-modal>
    <a-modal title="Withdraw Confrimation" v-model="modalConfirmation" @cancel="() => modalConfirmation = false" @ok="submit">
      <div class="text-bold" v-if="selectedBank">
        anda akan menarik dana sebesar {{ form.getFieldValue('amount') | currency }} akan ditransfer ke {{ selectedBank.bank_name }} / {{ selectedBank.bank_accountnr }} / {{ selectedBank.bank_accountname }}
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tick from '@/components/Icons/Tick'
import FormAddBank from '@/components/Form/Bank'
import FormOtpBank from '@/components/Form/Bank/FormOtp'

export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
    Tick,
    FormAddBank,
    FormOtpBank,
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      isSuccessWithdraw: false,
      modalVisible: false,
      modalSelectBank: false,
      selectedBank: null,
      disabledForm: false,
      modalConfirmation: false,
      rules: {
        value: [
          {
            validator: (rule, value, callback) => {
              if (value > this.commission.ewallet) {
                // eslint-disable-next-line standard/no-callback-literal
                callback(this.$t('account.notEnoughEwallet'))
              } else if (value < this.withdraw_min_amount) {
                // eslint-disable-next-line standard/no-callback-literal
                callback(this.$t('account.minimumEwallet', { value: this.withdraw_min_amount }))
              } else {
                callback()
              }
            },
          },
        ],
      },
      timestamp: 0,
      otp: null,
      otp_id: null,
      startCountdown: null,
      modalOtp: false,
      bank_id: null,
      withdraw_id: null,
      amount: 0,
    }
  },
  methods: {
    submit () {
      this.form.validateFieldsAndScroll((err, values) => {
        if (err) return
        this.amount = values.amount
        if (this.timestamp && ((Number(this.timestamp) - Number(this.actualTime)) > 0)) {
          this.modalOtp = true
          return
        }
        this.$store.dispatch('account/WITHDRAW', { bank_id: this.selectedBank.id_bank_account, ...values })
          .then(_ => {
            this.isSuccessWithdraw = true
          })
          .catch(err => {
            if (err.response?.status === 422 && err.response?.data?.otp_id && err.response?.data?.expireotptime) {
              this.modalOtp = true
              this.timestamp = err.response.data.expireotptime.toString()
              this.otp_id = err.response.data.otp_id
              this.bank_id = err.response.data.bank_id
              this.withdraw_id = err.response.data.id
              this.startCountdown = true
            } else if (err.response?.status === 422 && err.response?.data?.message === 'Cannot recreate token, your current token still active') {
              this.modalOtp = true
            } else {
              this.$notification.error({
                message: 'Withdraw Failed',
                description: err.response?.data?.message || err.message,
              })
            }
          })
          .finally(() => {
            this.modalConfirmation = false
          })
      })
    },
    openModal () {
      this.modalSelectBank = false
      this.modalVisible = true
      this.$store.dispatch('data/GETBANKLIST')
    },
    handleCancel () {
      this.modalVisible = false
    },
    setSelectedBank (value) {
      const findBank = this.bank.filter(item => item.id_bank_account === value)
      this.$store.dispatch('account/SETDEFAULTBANK', {
        ...findBank[0],
      }).then(() => {
        this.selectedBank = findBank[0]
        this.modalSelectBank = false
      })
    },
    inputNumberChange (e) {
      if (e > this.commission.ewallet) {
        this.disabledForm = true
      } else {
        this.disabledForm = false
      }
    },
    handleModalFormBank (value) {
      this.modalVisible = value
    },
    resendOtp () {
      this.$store.dispatch('account/RESENDOTP', {
        prev_otp_id: this.otp_id,
        bank_name: this.selectedBank.bank_name,
        accountnumber: this.selectedBank.bank_accountnr,
        accountholdername: this.selectedBank.bank_accountname,
      }).then(({ expireotptime, otp_id }) => {
        this.timestamp = expireotptime
        this.otp_id = otp_id
        this.$refs.otpBank.startCountdown()
      })
    },
    verifyWithdraw () {
      this.amount = this.form.getFieldValue('amount')
      this.$store.dispatch('account/VERIFYWITHDRAW', {
        id_bank_account: this.selectedBank.id_bank_account,
        otpcode: this.otp,
        otp_id: this.otp_id,
        amount: this.form.getFieldValue('amount'),
        bank_id: this.bank_id,
        withdraw_id: this.withdraw_id,
      })
        .then(() => {
          this.otp = null
          this.otp_id = null
          this.modalOtp = false
          this.bank_id = null
          this.withdraw_id = null
          this.isSuccessWithdraw = true
          this.form.resetFields()
          // return this.submit()
        })
        .catch(err => {
          this.$notification.error({
            message: 'Verify Failed',
            description: err.response?.data?.message || err.message,
          })
        })
    },
    handleVerifyBank () {
      this.verifyWithdraw()
    },
    handleResendOtp () {
      this.resendOtp()
    },
    setOtpBank (value) {
      this.otp = value
    },
    handleCancelModalOtp () {
      this.modalOtp = false
      // this.otp_id = null
      this.otp = null
    },
  },
  watch: {
    bank: function (val) {
      const data = val.find(item => item.is_default)
      this.selectedBank = data
    },
  },
  computed: {
    ...mapState('account', {
      bank: state => state.bank,
      loading: state => state.loading,
      profile: state => state.profile,
    }),
    ...mapState('dashboard', {
      commission: state => state.commission,
    }),
    ...mapState('data', {
      bankList: state => state.bankList,
    }),
    withdraw_min_amount () {
      return this.$store.state.app.withdraw_min_amount
    },
    actualTime () {
      return this.$moment().format('X')
    },
    phoneNumber () {
      if (this.profile) {
        return this.profile.user_data.phone
      }
      return 0
    },
  },
  created () {
    this.$store.dispatch('account/GETUSERBANK')
    this.$store.dispatch('account/GETEWALLET')
    if (!this.profile) {
      this.$store.dispatch('account/GETUSERPROFILE')
    }
  },
}
</script>

<style scoped>
.radio {
  display: block;
  height: 30px;
  line-height: 30px;
}
</style>
