<template>
  <div class="row">
    <div class="col-lg-12 py-5 px-5">
      <div class="text-muted mb-3 d-flex justify-content-center">
        <p>
          Please insert the OTP code that we just send to your phone number:
          <span style="color: #000000;"> {{ phoneNumber }} </span>,
          to verified your Bank Account
        </p>
      </div>
      <OTP @on-complete="handleOtpCompleted" :timestamp="timestamp" :modalState="modalState" :isSubmited="isSubmited" class="d-flex justify-content-center" />
      <div class="mt-3 d-flex justify-content-between">
        <div :class="{'text-muted': (minutes && seconds), 'resendOtp': (!minutes && !seconds) }" @click.prevent="submitResendOtp"> Resend OTP </div>
        <div class="text-muted"> {{ minutes }} : {{ seconds }} </div>
      </div>
      <div class="mt-3 d-flex flex-row-reverse">
        <a-button
          type="primary"
          size="large"
          class="text-center"
          :disabled="(!minutes && !seconds) || (!otp || otp.length !== 6 ) "
          @click.prevent="submit()"
        >
          <strong> Next </strong>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import OTP from '@/components/OTP/index'

export default {
  components: {
    OTP,
  },
  props: {
    timestamp: {
      type: String,
    },
    isStartCountdown: {
      type: Boolean,
    },
    otp: {
      type: String,
    },
    modalState: {
      type: Boolean,
    },
    phoneNumber: {
      type: String,
    },
  },
  data: function () {
    return {
      actualTime: this.$moment().format('X'),
      minutes: 0,
      seconds: 0,
      isSubmited: false,
    }
  },
  methods: {
    addOneSecondToActualTime () {
      this.actualTime = this.$moment().format('X')
      setTimeout(() => {
        if (!this.minutes && !this.seconds) {
          clearInterval()
        } else {
          this.addOneSecondToActualTime()
        }
      }, 1000)
    },
    getDiffInSeconds () {
      // console.log(this.timestamp - this.actualTime, 'timestampt dikurangi actual')
      // console.log(this.timestamp, 'timstamp')
      // console.log(this.actualTime, 'actual time')
      return this.timestamp - this.actualTime
    },
    compute () {
      // console.log('in compute')
      const duration = this.$moment.duration(this.getDiffInSeconds(), 'seconds')
      this.minutes = duration.minutes() > 0 ? duration.minutes() : 0
      this.seconds = duration.seconds() > 0 ? duration.seconds() : 0
      // console.log(this.minutes, 'in compute')
      // console.log(this.seconds, 'in compute')
    },
    startCountdown () {
      // console.log('di countdwon')
      this.compute()
      this.addOneSecondToActualTime()
    },
    handleOtpCompleted (otp) {
      this.$emit('setOtpBank', otp)
    },
    submitResendOtp () {
      if (!this.minutes && !this.seconds) {
        this.$emit('handleResendOtp')
      }
    },
    submit () {
      this.isSubmited = true
      this.$emit('handleVerifyBank')
    },
  },
  watch: {
    actualTime (newVal, oldVal) {
      if (!this.seconds && !this.minutes) {
        return null
      } else {
        this.compute()
      }
    },
    timestamp (newVal) {
      this.startCountdown()
    },
  },
  created: function () {
    if (this.isStartCountdown) {
      this.startCountdown()
    }
  },
}
</script>

<style>
.resendOtp {
  cursor: pointer;
  color: blue;
}
</style>
