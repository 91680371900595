<template>
  <div class="d-flex justify-content-center mt-3 mb-3 mr-3">
    <input
      ref="input"
      :type="inputType"
      min="0"
      max="9"
      maxlength="1"
      pattern="[0-9]"
      v-model="model"
      :class="inputClasses"
      @input="handleOnChange"
      @keydown="handleOnKeyDown"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    >
  </div>
</template>

<script>
export default {
  name: 'SingleInputOtp',
  props: {
    value: {
      type: String,
    },
    separator: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
    inputClasses: {
      type: String,
    },
    shouldAutoFocus: {
      type: Boolean,
    },
    inputType: {
      type: String,
      default() {
        return 'number'
      },
    },
    isLastChild: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      model: this.value || '',
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue
      }
    },
    focus (newFocusValue, oldFocusValue) {
      if (oldFocusValue !== newFocusValue && (this.$refs.input && this.focus)) {
        this.$refs.input.focus()
        this.$refs.input.select()
      }
    },
  },
  methods: {
    handleOnChange () {
      if (this.model.length > 1) {
        this.model = this.model.slice(0, 1)
      }
      return this.$emit('on-change', this.model)
    },
    handleOnKeyDown(event) {
      // Only allow characters 0-9, DEL, Backspace and Pasting
      const keyEvent = (event) || window.event
      const charCode = (keyEvent.which) ? keyEvent.which : keyEvent.keyCode
      if (this.isCodeNumeric(charCode) || (charCode === 8) || (charCode === 86) || (charCode === 46)) {
        this.$emit('on-keydown', event)
      } else {
        keyEvent.preventDefault()
      }
    },
    isCodeNumeric(charCode) {
      // numeric keys and numpad keys
      return (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)
    },
    handleOnFocus() {
      this.$refs.input.select()
      return this.$emit('on-focus')
    },
    handleOnBlur() {
      return this.$emit('on-blur')
    },
  },
}
</script>

<style lang="scss" scoped>
input{
  width: 35px;
  height: 35px;
  padding: 0;
  margin-right: 25px;
  text-align: center;
  border: 1px solid gray;
  border-radius: 5px;
  &:last-child{
    margin-right: 0;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  &:focus,
  &.focus{
    border-color: green;
    outline: none;
    box-shadow: none;
  }
}
</style>
