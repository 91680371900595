<template>
  <WithdrawRequest />
</template>

<script>
import WithdrawRequest from '@/components/WithdrawRequest'

export default {
  components: {
    WithdrawRequest,
  },
}
</script>
