var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Bank"}},[(!_vm.initialValue)?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'bank',
          {
            rules: [{
              required: true,
            }],
          }
        ]),expression:"[\n          'bank',\n          {\n            rules: [{\n              required: true,\n            }],\n          }\n        ]"}],attrs:{"show-search":""}},_vm._l((_vm.bankList),function(bank,index){return _c('a-select-option',{key:index,attrs:{"value":bank.label+'_'+index}},[_vm._v(" "+_vm._s(bank.label)+" ")])}),1):_vm._e(),(_vm.initialValue)?_c('div',[_vm._v(" "+_vm._s(_vm.initialValue.bank_name)+" ")]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"Account Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'accountnumber',
          {
            rules: [
              {
                required: true,
              },
              {
                pattern: new RegExp('^[0-9]*$'),
                message: _vm.$t('authForm.requiredAccountNo')
              }
            ],
            initialValue: _vm.initialValue ? _vm.initialValue.bank_accountnr : null
          },
        ]),expression:"[\n          'accountnumber',\n          {\n            rules: [\n              {\n                required: true,\n              },\n              {\n                pattern: new RegExp('^[0-9]*$'),\n                message: $t('authForm.requiredAccountNo')\n              }\n            ],\n            initialValue: initialValue ? initialValue.bank_accountnr : null\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"Account Holder Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'accountholdername',
          {
            rules: [{
              required: true
            }],
            initialValue: _vm.initialValue ? _vm.initialValue.bank_accountname : null
          },
        ]),expression:"[\n          'accountholdername',\n          {\n            rules: [{\n              required: true\n            }],\n            initialValue: initialValue ? initialValue.bank_accountname : null\n          },\n        ]"}]})],1),_c('a-form-item',[_c('a-button',{staticClass:"text-center mr-2",attrs:{"type":"primary","htmlType":"submit","loading":_vm.loading}},[_c('strong',[_vm._v(" Submit ")])]),_c('a-button',{staticClass:"text-center ml-2",on:{"click":function($event){$event.preventDefault();return _vm.cancelModal()}}},[_c('strong',[_vm._v("Cancel")])])],1)],1),_c('div',[_c('a-modal',{attrs:{"footer":null},on:{"cancel":_vm.handleCancelModalOtp},model:{value:(_vm.modalOtp),callback:function ($$v) {_vm.modalOtp=$$v},expression:"modalOtp"}},[_c('FormOtpBank',{ref:"otpBank",attrs:{"phoneNumber":_vm.phoneNumber,"modalState":_vm.modalOtp,"timestamp":_vm.timestamp,"isStartCountdown":_vm.startCountdown,"otp":_vm.otp},on:{"handleVerifyBank":_vm.handleVerifyBank,"handleResendOtp":_vm.handleResendOtp,"setOtpBank":_vm.setOtpBank}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }