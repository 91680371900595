<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="1.3em" viewBox="0 0 128 128" width="1.3em">
    <g>
      <circle cx="64" cy="64" fill="#a2faf3" r="45.877"/>
      <circle cx="64" cy="64" fill="#6cf7ec" r="37.794"/>
      <path d="m59.524 81.447a4.473 4.473 0 0 1 -3.212-1.359l-14.548-14.988a4.476 4.476 0 1 1 6.424-6.234l11.223 11.562 20.3-22.4a4.477 4.477 0 0 1 6.636 6.01l-23.5 25.942a4.481 4.481 0 0 1 -3.247 1.467z" fill="#fff"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TickSvg',
}
</script>
