<template>
  <div>
    <a-form :form="form" @submit.prevent="submit">
      <a-form-item label="Bank">
        <a-select
          show-search
          v-decorator="[
            'bank',
            {
              rules: [{
                required: true,
              }],
            }
          ]"
          v-if="!initialValue"
        >
          <a-select-option
            :value="bank.label+'_'+index"
            v-for="(bank, index) in bankList"
            :key="index"
          >
            {{ bank.label }}
          </a-select-option>
        </a-select>
        <div v-if="initialValue"> {{ initialValue.bank_name }} </div>
      </a-form-item>
      <a-form-item label="Account Number">
        <a-input
          v-decorator="[
            'accountnumber',
            {
              rules: [
                {
                  required: true,
                },
                {
                  pattern: new RegExp('^[0-9]*$'),
                  message: $t('authForm.requiredAccountNo')
                }
              ],
              initialValue: initialValue ? initialValue.bank_accountnr : null
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="Account Holder Name"
      >
        <a-input
          v-decorator="[
            'accountholdername',
            {
              rules: [{
                required: true
              }],
              initialValue: initialValue ? initialValue.bank_accountname : null
            },
          ]"
        />
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          class="text-center mr-2"
          htmlType="submit"
          :loading="loading"
        >
          <strong> Submit </strong>
        </a-button>
        <a-button
          class="text-center ml-2"
          @click.prevent="cancelModal()"
        >
          <strong>Cancel</strong>
        </a-button>
      </a-form-item>
    </a-form>
    <div>
      <a-modal v-model="modalOtp" :footer="null" @cancel="handleCancelModalOtp">
        <FormOtpBank
          @handleVerifyBank="handleVerifyBank"
          @handleResendOtp="handleResendOtp"
          @setOtpBank="setOtpBank"
          :phoneNumber="phoneNumber"
          :modalState="modalOtp"
          :timestamp="timestamp"
          :isStartCountdown="startCountdown"
          :otp="otp"
          ref="otpBank"
        />
      </a-modal>
    </div>
  </div>
</template>

<script>
// import OTP from '@/components/OTP'
import FormOtpBank from './FormOtp'

export default {
  components: {
    // OTP,
    FormOtpBank,
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      modalOtp: false,
      timestamp: 0,
      otp: null,
      otp_id: null,
      startCountdown: null,
    }
  },
  props: {
    initialValue: {
      type: Object,
    },
    bankList: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    bank: {
      type: Array,
    },
    isFromWithdraw: {
      type: Boolean,
      default: false,
    },
    isCancelModal: {
      type: Boolean,
    },
    phoneNumber: {
      type: String,
    },
  },
  methods: {
    submit () {
      this.form.validateFieldsAndScroll((err, values) => {
        if (err) return
        const id_bank_account = this.initialValue ? this.initialValue.id_bank_account : null
        const bank_name = this.initialValue ? this.initialValue.bank_name : null
        const isFromWithdraw = this.isFromWithdraw ? true : null
        if (
          id_bank_account &&
          this.initialValue.is_verified &&
          values.accountholdername === this.initialValue.bank_accountname &&
          values.accountnumber === this.initialValue.bank_accountnr
        ) {
          this.form.resetFields()
          this.$emit('handle-modal', false)
          return
        }
        this.$store.dispatch('account/EDITUSERBANK', { id_bank_account, bank_name, isFromWithdraw, ...values })
          .then(({ expireotptime, otp_id }) => {
            this.$emit('handle-modal', false)
            this.timestamp = expireotptime
            this.otp_id = otp_id
            this.startCountdown = true
            this.modalOtp = true
          })
          .catch(err => {
            // Cannot recreate token, your current token still active
            if (err.response?.status === 422 && err.response?.data?.message === 'Cannot recreate token, your current token still active') {
              this.modalOtp = true
              this.$emit('handle-modal', false)
            } else {
              this.$notification.error({
                message: `${id_bank_account ? this.$t('account.editBankFailed') : this.$t('account.addBankFailed')}`,
                description: err.response?.data?.message || err.message,
              })
            }
          })
      })
    },
    resendOtp () {
      const id_bank_account = this.initialValue ? this.initialValue.id_bank_account : null
      const bank_name = this.initialValue ? this.initialValue.bank_name : null
      const isFromWithdraw = this.isFromWithdraw ? true : null
      this.$store.dispatch('account/RESENDOTP', {
        prev_otp_id: this.otp_id,
        id_bank_account,
        bank_name,
        isFromWithdraw,
        ...this.form.getFieldsValue(),
      }).then(({ expireotptime, otp_id }) => {
        this.timestamp = expireotptime
        this.otp_id = otp_id
        // this.$refs.otpBank.startCountdown()
      }).catch(err => {
        this.$notification.error({
          message: 'Resend Otp Failed',
          description: err.response?.data?.message || err.message,
        })
      })
    },
    handleCancelModalOtp () {
      this.modalOtp = false
      // this.otp_id = null
      // this.otp = null
    },
    verifyBank () {
      const id_bank_account = this.initialValue ? this.initialValue.id_bank_account : null
      // const findBank = this.bank.find((item) => item.bank_accountname === this.form.getFieldValue('accountholdername') && item.bank_accountnr === this.form.getFieldValue('accountnumber'))
      // const id_bank = findBank.id_bank_account
      const otpcode = this.otp
      this.$store.dispatch('account/VERIFYBANK', {
        // id_bank_account: id_bank,
        otpcode,
        otp_id: this.otp_id,
      }).then(() => {
        this.form.resetFields()
        this.initialValue = null
        this.otp = null
        this.otp_id = null
        this.modalOtp = false
        this.$notification.success({
          message: `${id_bank_account ? this.$t('account.editBankSuccess') : this.$t('account.addBankSuccess')}`,
        })
      }).catch(err => {
        this.$notification.error({
          message: `${id_bank_account ? this.$t('account.editBankFailed') : this.$t('account.addBankFailed')}`,
          description: err.response?.data?.message || err.message,
        })
      })
    },
    // handleOtpCompleted (otp) {
    //   this.otp = otp
    // },
    handleVerifyBank () {
      this.verifyBank()
    },
    handleResendOtp () {
      this.resendOtp()
    },
    setOtpBank (value) {
      this.otp = value
    },
    cancelModal () {
      this.form.resetFields()
      this.$emit('handle-modal', false)
    },
  },
  watch: {
    isCancelModal (newVal) {
      if (newVal) {
        this.form.resetFields()
        this.$emit('handle-modal', false)
      }
    },
  },
}
</script>
